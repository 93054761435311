import type { ComponentType } from "react"

export function withHorizontalDrag(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                drag="x" // Enable horizontal dragging
                dragConstraints={{ left: -2129, right: 0 }} // Customize constraints
                dragTransition={{
                    power: 0.2, // Reduce power for slower movement
                    timeConstant: 100, // Higher value makes drag decelerate slower
                }}
                style={{ cursor: "grab" }} // Change cursor style during drag
                onDragStart={() => console.log("Drag started")}
                onDragEnd={() => console.log("Drag ended")}
            />
        )
    }
}
